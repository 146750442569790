<template>
  <div @click="showUsersReacted" class="user-react">
    <div class="user-react__outer-flex">
        <div class="user-react__outer-flex--image-conatiner">
            <div class="image" v-for="reaction in activeReactions" :key="reaction?.name">
                <img :src="reaction?.icon" />
            </div>
        </div>
    </div>
    <div class="user-react__name" @click="openModal()">
      {{ userReactionRepresentation }}
    </div>
  </div>
    <ListReactionUserModal
        :showModal="openListReactionUserModal"
        :articleId="articleId"
        @cancelModal="handleCancel()"
        :reactedUsers="reactedUsers"
        :isLoading="reactedUsersLoading"
        :allReaction="reactions"
    ></ListReactionUserModal>
</template>
<script>
import Like from "../../assets/icons/reactions/Like.png";
import Love from "../../assets/icons/reactions/Love.png";
import Insightful from "../../assets/icons/reactions/Insightful.png";
import Support from "../../assets/icons/reactions/Support.png";
import Celebration from "../../assets/icons/reactions/Celebration.png";
import Laugh from "../../assets/icons/reactions/Laugh.png";
import Sad from "../../assets/icons/reactions/Sad.png";
import Angry from "../../assets/icons/reactions/Angry.png";
import ListReactionUserModal from "./ListReactionUserModal.vue";
import {mapActions} from "vuex";


export default {
  components: {
    ListReactionUserModal,
  },
  props: {
    totalArticleReactions: Object,
    userReactionRepresentation: String,
    articleId: String
  },
  data: () => ({
    reactions: [
      {
        name: "like",
        icon: Like,
        alt: "Like emoji",
        mode: "like",
      },
      {
        name: "love",
        icon: Love,
        alt: "Love emoji",
        mode: "love",
      },
      {
        name: "laugh",
        icon: Laugh,
        alt: "Laugh emoji",
        mode: "laugh",
      },

      {
        name: "sad",
        icon: Sad,
        alt: "Sad emoji",
        mode: "sad",
      },
      {
        name: "angry",
        icon: Angry,
        alt: "Angry emoji",
        mode: "angry",
      },
      {
        name: "insightful",
        icon: Insightful,
        alt: "Insightful",
        mode: "insightful",
      },
      {
        name: "celebrate",
        icon: Celebration,
        alt: "Celebration emoji",
        mode: "celebration",
      },
      {
        name: "support",
        icon: Support,
        alt: "Support emoji",
        mode: "support",
      },
    ],
    activeReactions: [],
    activeReactionsCount: null,
    isOpen: false,
    reactedUsers: [],
    reactedUsersLoading: false
  }),
  computed: {
    openListReactionUserModal() {
      return this.isOpen
    }
  },
  methods: {
    ...mapActions('article', ['fetchArticleReactedUsers']),
    async showUsersReacted() {
      const payload = {
        articleId: this.articleId
      }
      this.reactedUsersLoading = true;
      const response = await this.fetchArticleReactedUsers(payload)
      this.reactedUsersLoading = false;
      this.reactedUsers = response.data.article_reaction.map(users => {
        return { userName: users.user.full_name, profilePicture: users.user.profile_picture , reaction: users.reaction }
      })
    },
    countReactions(reactionCountsArray) {
      //adding all the reaction counts
      reactionCountsArray.map(counts => {
        this.activeReactionsCount += counts

      })

      // subtracting one count as we will show the connection name as well
      this.activeReactionsCount -= 1
    },

    sortReactions(totalArticleReactions) {
      return Object.fromEntries(
          Object.entries(totalArticleReactions).sort(([, a], [, b]) => a - b)
      )
    },

    getCommonActiveReaction(reactionTypeArray, reactions) {
      const commonActiveReactions = reactionTypeArray.map(activeReaction => {
        return reactions.find(reaction => reaction.name === activeReaction)
      })


      if (commonActiveReactions.length > 3) {
        return commonActiveReactions.splice(2)
      } else
        return commonActiveReactions
    },
    openModal() {
      this.isOpen = !this.isOpen
    },
    handleCancel() {
      this.isOpen = false
    }
  },
  watch:{
    totalArticleReactions:{
      handler(val){
        if (typeof this.totalArticleReactions === 'object') {
        const sortedReactions = this.sortReactions(this.totalArticleReactions)
        const reactionTypes = Object.keys(sortedReactions)
        const reactionCounts = Object.values(sortedReactions)
        this.countReactions(reactionCounts)
        this.activeReactions = this.getCommonActiveReaction(reactionTypes, this.reactions)
    }
      },
      immediate:true
    }
  },
  // mounted() {
  //   if (typeof this.totalArticleReactions === 'object') {
  //     const sortedReactions = this.sortReactions(this.totalArticleReactions)
      
  //     const reactionTypes = Object.keys(sortedReactions)
      
  //     const reactionCounts = Object.values(sortedReactions)
      
  //     this.countReactions(reactionCounts)
  //     this.activeReactions = this.getCommonActiveReaction(reactionTypes, this.reactions)
  //   }
  // }
}
</script>
<style lang="scss" scoped>
.user-react {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  &__outer-flex {
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    align-items: center;

    &--image-conatiner {
      display: flex;
      align-items: center;

      .image {
        height: 1.5rem;
        width: 1.5rem;
        margin-left: -0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          margin-left: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  &__name {
    font-size: 1.2rem;
    margin-left: 0.8rem;
    cursor: pointer;
    line-height: normal;
    font-family: $font-primary-medium;
    width: max-content;
    @include respond(phone-x-small) {
      font-size: 1.4rem;
    }
  }
}

</style>